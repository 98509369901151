import {Button} from "@mui/material";
import React, {useCallback, useContext, useState} from "react";
import {useCreateNextPayStubMutation} from "../../graphql/generated/graphql";
import {useNavigate} from "react-router-dom";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import {assumedUserContext} from "../User/AssumedUserContext";
import SelectNextPayDateDialog from "./SelectNextPayDateDialog";

type PropsType = {
    previousPayStubId: number;
}

const CreateNextPayStubButton = (props: PropsType) => {

    const {previousPayStubId} = props;
    const navigate = useNavigate();
    const {sendNotice} = useSystemNotices();
    const {assumedUser} = useContext(assumedUserContext);
    const [openChoosePayDate, setOpenChoosePayDate] = useState(false);


    const [error, setError] = useState<string>();

    const [
        createNextPayStub,
        {
            loading: createNextPayStubLoading,
        }
    ] = useCreateNextPayStubMutation();

    const createNextPayStubNow = useCallback((nextPayDate: string) => {
        if (createNextPayStubLoading) {
            return;
        }
        if (!assumedUser) {
            return;
        }

        if (!nextPayDate) {
            sendNotice(`No next pay date found for pay stub with ID: ${previousPayStubId}. Cannot create next pay stub.`);
            return;
        }

        createNextPayStub({
            variables: {
                previousPayStubId: previousPayStubId,
                userId: assumedUser.id,
                nextPayDate: nextPayDate
            }
        })
            .then(result => {
                let nextPayStub = result.data?.createNextPayStub;
                if (!!nextPayStub?.successful && nextPayStub.payStubWithDeductions) {
                    navigate(`/pay-stub/${nextPayStub.payStubWithDeductions.payStub.id}`);
                } else if (!!nextPayStub?.message) {
                    sendNotice(`Error creating next pay stub for previous pay stub ID: ${previousPayStubId}. Error: ${nextPayStub.message}`);
                    setError(nextPayStub.message);
                } else {
                    sendNotice(`Error creating next pay stub for previous pay stub ID: ${previousPayStubId}. Error: Unknown error. This should not be possible.`);
                    setError("Unknown error. Please try again or contact support.");
                }
            })
            .catch(error => {
                sendNotice(`Error creating next pay stub for previous pay stub ID: ${previousPayStubId}. Error: ${error.message}`);
                setError("Error creating next pay stub. Please try again or contact support.");
            });

    }, [assumedUser, createNextPayStub, createNextPayStubLoading, navigate, previousPayStubId, sendNotice]);

    return <>
        <Button
            onClick={() => setOpenChoosePayDate(true)}
            variant={"contained"}
        >
            Create next pay stub
        </Button>
        <LoadingErrorDisplay
            loading={createNextPayStubLoading}
            stringError={error}
        />
        {
            openChoosePayDate &&
            <SelectNextPayDateDialog
                payStubId={previousPayStubId}
                setNextPayDate={nextPayDate => {
                    createNextPayStubNow(nextPayDate);
                }}
                close={() => setOpenChoosePayDate(false)}
            />
        }

    </>

}
export default CreateNextPayStubButton;