import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import {MobileDatePicker} from "@mui/x-date-pickers";
import {DATE_FORMAT, getDateFromString, getStringFromNullableDate} from "../../Utils/dateFormatter";
import React, {useCallback, useEffect, useState} from "react";
import {useGetNextPayDateQuery} from "../../graphql/generated/graphql";
import useSystemNotices from "../../Utils/useSystemNotices";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import ErrorDisplay from "../../common/ErrorDisplay";
import CloseDialogButton from "../../common/CloseDialogButton";

type PropsType = {
    payStubId: number;
    setNextPayDate: (date: string) => void;
    close: () => void;
}

const SelectNextPayDateDialog = (props: PropsType) => {
    const {payStubId, setNextPayDate, close} = props;
    const {sendNotice} = useSystemNotices();

    const [nextDate, setNextDate] = useState<string>();
    const [error, setError] = useState<string>();

    const {
        data: nextPayDateData
    } = useGetNextPayDateQuery({
        variables: {
            payStubId: payStubId
        }
    });


    useEffect(() => {
        if (!nextPayDateData) {
            return;
        }
        let nextPayDate = nextPayDateData?.getNextPayDate.nextPayDate;
        if (!nextPayDate) {
            sendNotice(`No next pay date found for pay stub with ID: ${payStubId}. Message: ${nextPayDateData?.getNextPayDate.message}`);
            setError('Error auto-populating the next pay date. Please select a date and click Continue.');
            return;
        }
        setNextDate(nextPayDate);
    }, [nextPayDateData, payStubId, sendNotice]);

    const updatePayDateAndClose = useCallback(() => {
        if (!nextDate) {
            setError("Please choose the next pay date or cancel");
            return;
        }
        setNextPayDate(nextDate);
        close();
    }, [nextDate, close, setNextPayDate]);


    if (!nextDate) {
        return <LoadingErrorDisplay
            loading={true}
        />
    }


    return <Dialog
        open={!!nextDate}
    >


        <DialogTitle>
            {`Based on the pay frequency for this employee, the next pay date is ${nextDate}.
             YTD values in the new pay stub will be automatically calculated based on the previous pay stub. Dates and current period
            amounts will also be generated but should be reviewed to ensure they accurately reflect the pay period.`}
            <Typography variant="h6" gutterBottom style={{fontWeight: "bold", marginTop: "8px"}}>
                Note that YTD numbers will automatically reset if the pay date is in a new year. Please confirm or
                change it as needed.
            </Typography>
            <CloseDialogButton
                close={close}
            />
        </DialogTitle>
        <DialogContent>
            <Box sx={{mt: 1}}>
                <MobileDatePicker
                    label="Next pay date"
                    value={getDateFromString(nextDate)}
                    onChange={(date) => setNextDate(getStringFromNullableDate(date, DATE_FORMAT))}

                    sx={{
                        '& .MuiInputBase-root': {px: '3px'},
                        maxWidth: {xs: '110px', sm: '140px'},
                    }}
                />
            </Box>
            <ErrorDisplay
                stringError={error}
            />
        </DialogContent>
        <DialogActions>
            <Button
                onClick={() => {
                    close();
                }}>
                Cancel
            </Button>
            <Button
                onClick={updatePayDateAndClose}
            >
                Continue
            </Button>
        </DialogActions>
    </Dialog>

}
export default SelectNextPayDateDialog;