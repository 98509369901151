import {Box} from "@mui/material";
import React from "react";
import {ReportValidation} from "../../../graphql/generated/graphql";
import ErrorDisplay from "../../../common/ErrorDisplay";

type PropsType = {
    reportValidation: ReportValidation
};
const ReportValidationDisplay = (props: PropsType) => {

    const {reportValidation} = props;

    return <>
        <ErrorDisplay
            stringError={`${reportValidation.employeeName} (${reportValidation.employeeId})`}
        />
        {
            <Box sx={{ml: 2}}>
                <ErrorDisplay
                    stringError={reportValidation.messages}
                />
            </Box>
        }
    </>

}

export default ReportValidationDisplay;