import React, {useContext, useState} from "react";
import {
    IncomeType,
    TaxTreatment,
    useGetT4OtherIncomeTypesQuery,
    useIncomeTypeTaxTreatmentsQuery,
    useSaveIncomeTypeMutation
} from "../../graphql/generated/graphql";
import {Box, Button, Grid2, MenuItem, TextField, Typography} from "@mui/material";
import {useForm} from "react-hook-form";
import ReactHookFormSelect from "../../common/ReactHookFormSelect";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import useAnonCookie from "../../security/useAnonCookie";
import GET_INCOME_TYPES from "../../graphql/queries/getIncomeTypes";
import useSystemNotices from "../../Utils/useSystemNotices";
import {assumedUserContext} from "../User/AssumedUserContext";
import OneTwoPayTooltip from "../../common/OneTwoPayTooltip";

type PropsType = {
    currentPayStubId: number | undefined;
    incomeType: IncomeType;
    setIncomeType: (incomeType: IncomeType) => void;
    close: () => void;
}

type IncomeTypeForForm = {
    id: number
    label: string
    rateLabel: string
    taxTreatmentCode: string;
    unitsLabel: string;
    t4OtherIncomeTypeCode: string;
}


function getIncomeTypeForForm(incomeType: IncomeType): IncomeTypeForForm {
    return {
        id: incomeType.id,
        label: incomeType.label,
        rateLabel: incomeType.rateLabel,
        taxTreatmentCode: incomeType.taxTreatment.code,
        unitsLabel: incomeType.unitsLabel,
        t4OtherIncomeTypeCode: incomeType.t4OtherIncomeTypeCode ? incomeType.t4OtherIncomeTypeCode : "none"
    }
}

function getSelectedTaxTreatment(taxTreatments: TaxTreatment[], taxTreatmentCode: string): TaxTreatment | undefined {
    return taxTreatments.find(taxTreatment => taxTreatment.code === taxTreatmentCode);
}

const IncomeTypeEdit = (props: PropsType) => {
    const {
        incomeType,
        setIncomeType,
        currentPayStubId,
        close
    } = props;

    const [error, setError] = useState<string>();
    const {getAnonUserId} = useAnonCookie();
    const {sendNotice} = useSystemNotices();
    const {assumedUser} = useContext(assumedUserContext);

    const [
        saveIncomeType,
        {
            loading: saveIncomeTypeLoading,
            error: saveIncomeTypeError
        }] = useSaveIncomeTypeMutation();

    const {
        data: incomeTypeTaxTreatmentsData,
        loading: incomeTypeTaxTreatmentsLoading,
        error: incomeTypeTaxTreatmentsError
    } = useIncomeTypeTaxTreatmentsQuery();

    const {
        data: t4OtherIncomeTypesData,
        loading: t4OtherIncomeTypesLoading,
        error: t4OtherIncomeTypesError,
    } = useGetT4OtherIncomeTypesQuery();

    const {
        register,
        control,
        formState: {errors},
        handleSubmit,
        watch
    } = useForm<IncomeTypeForForm>({
        defaultValues: getIncomeTypeForForm(incomeType)
    });

    const saveNewIncomeType = (data: IncomeTypeForForm) => {
        // setIncomeType(undefined);
        saveIncomeType({
            variables: {
                input: {
                    id: data.id,
                    label: data.label,
                    rateLabel: data.rateLabel,
                    unitsLabel: data.unitsLabel,
                    taxTreatmentCode: data.taxTreatmentCode,
                    t4OtherIncomeTypeCode: data.t4OtherIncomeTypeCode,
                    userId: assumedUser.id
                },
                anonUserId: getAnonUserId(),
                payStubId: currentPayStubId
            },
            refetchQueries: [
                {
                    query: GET_INCOME_TYPES,
                    variables: {
                        anonUserId: getAnonUserId(),
                        userId: assumedUser.id
                    },
                }
            ]
        })
            .then(savedIncomeType => {
                let responseData = savedIncomeType.data;
                if (!responseData) {
                    sendNotice(`Error saving income type on payStubId: ${currentPayStubId}`);
                    setError("Error saving income type. Please try again or contact support.")
                } else {
                    setIncomeType(responseData.saveIncomeType);
                    close();
                }
            });
    }

    const taxTreatments = incomeTypeTaxTreatmentsData?.incomeTypeTaxTreatments;
    if (incomeTypeTaxTreatmentsLoading || incomeTypeTaxTreatmentsError || !taxTreatments || t4OtherIncomeTypesLoading || t4OtherIncomeTypesError || !t4OtherIncomeTypesData?.t4OtherIncomeTypes) {
        return <LoadingErrorDisplay
            loading={incomeTypeTaxTreatmentsLoading || !taxTreatments || t4OtherIncomeTypesLoading || !t4OtherIncomeTypesData?.t4OtherIncomeTypes}
            apolloError={incomeTypeTaxTreatmentsError || t4OtherIncomeTypesError}
        />
    }

    const taxTreatmentCode = watch('taxTreatmentCode');
    const selectedTaxTreatment = getSelectedTaxTreatment(taxTreatments, taxTreatmentCode);

    return <form noValidate onSubmit={handleSubmit(saveNewIncomeType)}>
        <Grid2 container spacing={2} sx={{p: 2}}>
            <Grid2 size={12}>
                <TextField
                    fullWidth
                    label={"Label"}
                    {...register("label", {required: "Label is required"})}
                    error={!!errors.label}
                    helperText={errors.label?.message}
                />
            </Grid2>
            <Grid2 size={12}>
                {!!taxTreatments &&
                    <ReactHookFormSelect
                        control={control}
                        label={"Tax Treatment"}
                        nameOfFormField={`taxTreatmentCode`}
                        requiredMessage={"Tax treatment is required"}
                    >
                        {taxTreatments.map(taxTreatment => (
                            <MenuItem key={taxTreatment.code} value={taxTreatment.code}>
                                {taxTreatment.label}
                            </MenuItem>
                        ))}
                    </ReactHookFormSelect>
                }
            </Grid2>
            <Grid2 size={12} sx={{ml: 2, mb: 2}}>
                <Typography
                    variant="body2"
                >
                    {selectedTaxTreatment?.description}
                </Typography>
            </Grid2>
            <Grid2 size={6}>
                <TextField
                    fullWidth
                    label={"Rate Label"}
                    {...register("rateLabel", {required: "Rate label is required"})}
                    error={!!errors.rateLabel}
                    helperText={errors.rateLabel?.message}
                />
            </Grid2>
            <Grid2 size={6}>
                <TextField
                    fullWidth
                    label={"Units Label"}
                    {...register("unitsLabel", {required: "Units label is required"})}
                    error={!!errors.unitsLabel}
                    helperText={errors.unitsLabel?.message}
                />
            </Grid2>
            <Grid2 size={11}>
                <ReactHookFormSelect
                    control={control}
                    label={"T4 Other income (if applicable)"}
                    nameOfFormField={`t4OtherIncomeTypeCode`}
                >
                    {t4OtherIncomeTypesData.t4OtherIncomeTypes.map(t4OtherIncomeType => (
                        <MenuItem key={t4OtherIncomeType.t4OtherIncomeTypeCode}
                                  value={t4OtherIncomeType.t4OtherIncomeTypeCode}>
                            <Box>
                                {/* Render the main label */}
                                <Typography variant="body1">
                                    {t4OtherIncomeType.label}
                                </Typography>
                                {/* Render the description with a smaller font */}
                                <Typography variant="body2" color="text.secondary">
                                    {t4OtherIncomeType.description}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </ReactHookFormSelect>
            </Grid2>
            <Grid2 size={1}>
                <OneTwoPayTooltip
                    tipContent={"T4 Other Income Type is only required if / when you generate a T4 for an employee with pay stubs that use this income type." +
                        "\"Not applicable\" applies to most income types. Another value for this field should be used if you want to identify this income in one of the \"Other income\" boxes on the T4."}/>
            </Grid2>
            <Grid2 size={6}>
                <Button
                    variant={"contained"}
                    fullWidth
                    color={"error"}
                    onClick={close}
                >
                    {"Cancel"}
                </Button>
            </Grid2>
            <Grid2 size={6}>
                <Button
                    variant={"contained"}
                    fullWidth
                    type={"submit"}
                >
                    Save Income Type
                </Button>
            </Grid2>
            <Grid2 size={12}>
                <LoadingErrorDisplay
                    loading={saveIncomeTypeLoading}
                    apolloError={saveIncomeTypeError}
                    stringError={error}
                />
            </Grid2>
        </Grid2>
    </form>

}
export default IncomeTypeEdit;