import React, {useEffect, useState} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material'
import {useGeneratePayStubQuery} from '../../graphql/generated/graphql'
import LoadingErrorDisplay from '../../common/LoadingErrorDisplay'
import useAnonCookie from '../../security/useAnonCookie'
import {Document, Page} from "react-pdf";
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import useViewerStyle from "./useViewerStyle";
import CloseDialogButton from "../../common/CloseDialogButton";


type PropsType = {
    showDownload: boolean;
    setShowDownload: (show: boolean) => void;
    payStubId: number;
}

const PayStubDownload = (props: PropsType) => {
    const {showDownload, setShowDownload, payStubId} = props
    const {getAnonUserId} = useAnonCookie();
    const [errors, setErrors] = useState<string | Array<string> | null | undefined>();
    const {
        data: generatePayStubData,
        loading: generatePayStubLoading,
        error: generatePayStubError,
    } = useGeneratePayStubQuery({
        variables: {
            payStubId: payStubId,
            anonUserId: getAnonUserId()
        },
        fetchPolicy: "no-cache"
    });
    const {getPdfViewStyle} = useViewerStyle();


    useEffect(() => {
        if (!!generatePayStubData && !generatePayStubData.generatePayStub.generated) {
            setErrors(generatePayStubData.generatePayStub.messages);
        }
    }, [generatePayStubData]);

    const payStubUrl = generatePayStubData?.generatePayStub?.link
    return (
        <Dialog
            open={showDownload}
            fullWidth
            sx={{"& .MuiPaper-root": {maxHeight: 700}}}
        >
            <DialogTitle>
                Pay Stub Download
                <CloseDialogButton
                    close={() => setShowDownload(false)}
                />
            </DialogTitle>
            <DialogContent style={{maxHeight: "calc(100vh - 200px)", overflow: 'auto'}}>
                {!payStubUrl && (
                    <LoadingErrorDisplay
                        loading={generatePayStubLoading}
                        apolloError={generatePayStubError}
                        stringError={errors}
                    />)}
                {payStubUrl && (
                    <div style={getPdfViewStyle()}>
                        <Document
                            file={payStubUrl}
                        >
                            <Page pageNumber={1}/>
                        </Document>
                    </div>
                )}
            </DialogContent>

            <DialogActions
                style={{justifyContent: 'flex-start'}}>

                <Button
                    sx={{mt: 1, fontSize: '1.5rem'}}
                    variant={'contained'}
                    size={'large'}
                    onClick={() => setShowDownload(false)}
                >
                    Close
                </Button>
                <a href={payStubUrl || '#'}>
                    <Button
                        sx={{ml: 2, mt: 1, fontSize: '1.5rem'}}
                        variant={'contained'}
                        size={'large'}
                        disabled={generatePayStubLoading}
                    >
                        Download
                    </Button>
                </a>
            </DialogActions>
        </Dialog>
    )
}
export default PayStubDownload