import {gql} from "@apollo/client";

const GET_INCOME_TYPES = gql`

    query getIncomeTypes($anonUserId: String!, $userId: Int) {
        incomeTypes(anonUserId: $anonUserId, userId: $userId) {
            id
            code
            label
            rateLabel
            unitsLabel
            taxTreatment {
                code
                label
                description
            }
            displayOrder
            isBenefit
            t4OtherIncomeTypeCode
        }
    }`;

export default GET_INCOME_TYPES;