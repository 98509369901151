import {
    GetEmployeeQuery,
    PayStubBasic,
    useGetEmployeeQuery,
    useGetPayStubLazyQuery,
    useGetPreviousPayStubsForEmployeeQuery
} from "../../graphql/generated/graphql";
import React, {useCallback, useEffect, useState} from "react";
import {Box, Button, Grid2, IconButton, Typography, useMediaQuery, useTheme} from "@mui/material";
import {DataGrid, GridCellParams, GridColDef} from "@mui/x-data-grid";
import useAnonCookie from "../../security/useAnonCookie";
import PreviousPayStubDisplay from "./PreviousPayStubDisplay";
import LoadingErrorDisplay from "../../common/LoadingErrorDisplay";
import usePayStubIdManager from "./usePayStubIdManager";
import EditDialog from "../../common/EditDialog";
import ErrorDisplay from "../../common/ErrorDisplay";
import {styled} from "@mui/system";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {getFormattedDateStringWithMonthFromNullableDateOrString} from "../../Utils/dateFormatter";
import useSystemNotices from "../../Utils/useSystemNotices";
import EditIcon from "@mui/icons-material/Edit";
import SelectNextPayDateDialog from "./SelectNextPayDateDialog";

type PropsType = {
    employeeId: number;
    setPreviousPayStubId: (payStubId: number | undefined, payDate: string | undefined) => void;
    previousPayStubId: number | undefined | null;
    payDate: string
    isEditable: boolean;
}

type PayStubOptionRow = {
    id: number;
    payDate: string;
    payPeriod: string;
    finalized: boolean;
}

type MyColDef = GridColDef & {
    field: 'id' | 'payDate' | 'payPeriod' | 'useForYtd' | 'editPreviousStub',
}

interface MyGridCellParams extends GridCellParams {
    row: PayStubOptionRow;
}

// todo::Refactor this to its own component
const StyledGridOverlay = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
        fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
        fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
        fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
        fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
        fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
        fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
}));

// todo::Refactor this to its own component
function CustomNoRowsOverlay() {
    return (
        <StyledGridOverlay>
            <svg
                width="120"
                height="100"
                viewBox="0 0 184 152"
                aria-hidden
                focusable="false"
            >
                <g fill="none" fillRule="evenodd">
                    <g transform="translate(24 31.67)">
                        <ellipse
                            className="ant-empty-img-5"
                            cx="67.797"
                            cy="106.89"
                            rx="67.797"
                            ry="12.668"
                        />
                        <path
                            className="ant-empty-img-1"
                            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                        />
                        <path
                            className="ant-empty-img-2"
                            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                        />
                        <path
                            className="ant-empty-img-3"
                            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                        />
                    </g>
                    <path
                        className="ant-empty-img-3"
                        d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                    />
                    <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                        <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"/>
                        <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"/>
                    </g>
                </g>
            </svg>
            <Box sx={{mt: 1}}>Looks like no pay stubs have been created yet</Box>
        </StyledGridOverlay>
    );
}


const useColumnsForDataGrid = (updatePreviousPayStubId: (payStubId: number) => void, navigate: NavigateFunction): MyColDef[] => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return [
        {
            field: 'id',
            headerName: isSmallScreen ? '' : 'Pay Stub ID',
            width: isSmallScreen ? 20 : 100,
            disableColumnMenu: true,
            flex: isSmallScreen ? 0 : 1,
            cellClassName: 'cell',
            sortable: !isSmallScreen,
        },
        {
            field: 'payPeriod',
            headerName: 'Pay Period',
            flex: .5,
            cellClassName: 'cell',
            disableColumnMenu: !isSmallScreen,
        },
        {
            field: 'payDate',
            headerName: 'Pay Date',
            flex: 1,
            cellClassName: 'cell',
            sortable: false,
            disableColumnMenu: isSmallScreen,
            renderCell: (params) => {
                return params.value ? String(params.value) : 'N/A';
            }

        },
        {
            field: 'useForYtd',
            headerName: '',
            flex: 1,
            cellClassName: 'cell',
            sortable: !isSmallScreen,
            disableColumnMenu: isSmallScreen,
            renderCell: (params: MyGridCellParams) => {
                const {finalized} = params.row;
                const buttonFontSize = isSmallScreen ? '8px !important' : '1rem';
                if (finalized) {
                    return (
                        <Button
                            onClick={() => updatePreviousPayStubId(params.id as number)}
                            sx={{fontSize: buttonFontSize}}
                        >
                            Use for YTD
                        </Button>
                    );
                }
                return (
                    <Typography sx={{mt: 2, ml: 1, fontSize: buttonFontSize}}>
                        Not finalized
                    </Typography>
                );
            }
        },
        {
            field: 'editPreviousStub',
            headerName: '',
            width: isSmallScreen ? 5 : 200,
            cellClassName: 'cell',
            sortable: !isSmallScreen,
            disableColumnMenu: isSmallScreen,
            renderCell: (params: MyGridCellParams) => {
                const {finalized} = params.row;
                if (!finalized) {
                    if (isSmallScreen) {
                        return (
                            <IconButton
                                onClick={() => navigate(`/pay-stub/${params.id}`)}
                                size="small"
                                sx={{fontSize: 'inherit'}}
                            >
                                <EditIcon fontSize="small"/>
                            </IconButton>
                        );
                    } else {
                        return (
                            <Button
                                onClick={() => navigate(`/pay-stub/${params.id}`)}
                                sx={{fontSize: '1rem'}}
                            >
                                Edit or finalize
                            </Button>
                        );
                    }
                }
                return "";
            }
        }
    ];
};

function getOptionRows(previousStubs: Array<PayStubBasic>, thisPayStubId: number | undefined): Array<PayStubOptionRow> {
    return previousStubs
        .filter(previousStub => !thisPayStubId || Number(previousStub.id) !== thisPayStubId)
        .map(payStub => {
            return {
                id: Number(payStub.id),
                payDate: payStub.payDate,
                payPeriod: payStub.payPeriod,
                finalized: payStub.finalized
            }
        })
        .sort((option1: PayStubOptionRow, option2: PayStubOptionRow) => {
            let text2Upper = option2.payDate.toUpperCase();
            let text1Upper = option1.payDate.toUpperCase();
            return text1Upper > text2Upper ? -1 : text1Upper < text2Upper ? 1 : 0;
        });
}

function getEditDialogTitle(employeeData: GetEmployeeQuery | undefined) {
    if (!employeeData) {
        return "";
    }
    return `Please select the previous pay stub for ${employeeData.getEmployee.employee.firstName} ${employeeData.getEmployee.employee.lastName}`;
}


function getPreviousStubFilterMessage(payDate: string) {
    const formattedDate = getFormattedDateStringWithMonthFromNullableDateOrString(payDate);
    return <Typography variant="body1" color="textSecondary" sx={{mt: 2}}>
        Showing pay stubs with positive current period income and pay dates on or before {formattedDate}. Close this pop
        up and adjust the pay date forward
        to see more recent pay stubs in this list.
    </Typography>
}

const PreviousPayStubDisplayEdit = (props: PropsType) => {
    const {employeeId, setPreviousPayStubId, previousPayStubId, payDate, isEditable} = props;
    const {getAnonUserId} = useAnonCookie();
    const {getPayStubId} = usePayStubIdManager();
    const shouldOpenEditMode = !previousPayStubId && previousPayStubId !== 0 && !!employeeId;
    const [editMode, setEditMode] = useState<boolean>(shouldOpenEditMode);
    const [selectPayDatePayStubId, setSelectPayDatePayStubId] = useState<number>();
    const [previousPayStubs, setPreviousPayStubs] = useState<Array<PayStubOptionRow>>([]);
    const navigate = useNavigate();
    const payStubId = getPayStubId();
    const {sendNotice} = useSystemNotices();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const {
        data: previousStubsData,
        error: previousStubsError
    } = useGetPreviousPayStubsForEmployeeQuery(
        {
            variables: {
                employeeId: employeeId.toString(),
                payStubId: payStubId,
                maxPayDate: payDate,
                anonUserId: getAnonUserId()
            },
            fetchPolicy: "no-cache"
        }
    );
    const {
        data: employeeData,
        loading: employeeLoading,
        error: employeeError
    } = useGetEmployeeQuery({
        variables: {
            employeeId: employeeId,
            currentPayStubId: payStubId,
            anonUserId: getAnonUserId()
        }
    })

    useEffect(() => {
        if (shouldOpenEditMode) {
            setEditMode(true);
        }
    }, [shouldOpenEditMode]);

    const [
        getPayStub,
        {
            data: previousPayStubData,
            loading: payStubLoading,
            error: payStubError
        }
    ] = useGetPayStubLazyQuery();

    useEffect(() => {
        if (payStubLoading || payStubError) {
            return;
        }
        if (!!previousPayStubId) {
            if (!!previousPayStubData?.payStub && previousPayStubData.payStub.payStub.id === previousPayStubId) {
                return;
            }
            let anonUserId = getAnonUserId();
            if (!anonUserId) {
                sendNotice("anonUserId is empty in useEffect on PreviousPayStubDisplayEdit");
            }
            getPayStub({
                variables: {
                    id: previousPayStubId,
                    anonUserId: anonUserId
                }
            })
                .then(() => {/*do nothing. result captured by previousPayStubData */
                });
        }
    }, [sendNotice, previousPayStubId, payStubLoading, payStubError, getAnonUserId, getPayStub, previousPayStubData]);

    useEffect(() => {
        if (!!previousStubsData) {
            if (!!previousStubsData.getPreviousPayStubsForEmployee &&
                previousStubsData.getPreviousPayStubsForEmployee.length > 0
            ) {
                setPreviousPayStubs(getOptionRows(previousStubsData.getPreviousPayStubsForEmployee, payStubId));
            } else {
                setPreviousPayStubId(0, undefined);
            }
        }
    }, [previousStubsData, setPreviousPayStubId, payStubId, setPreviousPayStubs]);

    const setPreviousPayStubIdToZeroIfNotSet = useCallback(() => {
        if (!previousPayStubId) {
            setPreviousPayStubId(0, undefined);
        }
    }, [previousPayStubId, setPreviousPayStubId]);


    const updatePreviousPayStubId = (payStubId: number) => {
        setSelectPayDatePayStubId(payStubId);
        setEditMode(false);
    }

    const columns = useColumnsForDataGrid(updatePreviousPayStubId, navigate);

    if (!!employeeError) {
        sendNotice(`getEmployeeError: ${employeeError.message}`);
    }

    if (!!previousStubsError || employeeLoading || !!employeeError) {
        return <LoadingErrorDisplay
            loading={employeeLoading}
            apolloError={previousStubsError || employeeError}
        />
    }

    let selectedPayStub = previousPayStubData?.payStub;

    function getPayStubMiniPreview() {
        if (payStubLoading || payStubError) {
            return <LoadingErrorDisplay
                loading={payStubLoading}
                apolloError={payStubError}
            />
        }
        if (!selectedPayStub) {
            return <Typography variant="body2" color="textSecondary" gutterBottom>
                The Year-to-date (YTD) amounts from the previous pay stub will be added to the current period
                amounts on the new pay stub to get the new YTD amounts for the new pay stub.
            </Typography>

        }
    }

    let employeeHasPreviousPayStubs = !!previousStubsData && !!previousStubsData.getPreviousPayStubsForEmployee && previousStubsData.getPreviousPayStubsForEmployee.length > 0;
    if (!employeeHasPreviousPayStubs) {
        return <></>;
    }

    const dialogTitle = getEditDialogTitle(employeeData);
    if (!dialogTitle) {
        sendNotice(`dialotTitle is empty in PreviousPayStubDisplayEdit`);
    }

    const editButton = React.forwardRef<HTMLButtonElement>((props, ref) => {
        return !previousPayStubId
            ? <Button
                {...props}
                variant={'contained'}
                onClick={() => setEditMode(true)}
                ref={ref}
            >Select previous pay stub
            </Button>
            : <Button
                {...props}
                onClick={() => setEditMode(true)}
                ref={ref}
            >Change previous pay stub
            </Button>
    });

    const canEdit = isEditable && employeeHasPreviousPayStubs;
    const promptUserToSelectPrevious = isEditable && !previousPayStubId && employeeHasPreviousPayStubs;

    return <Grid2 container direction="column">
        {!!selectedPayStub &&
            <PreviousPayStubDisplay
                payStub={selectedPayStub}
            />
        }
        {
            canEdit &&
            <EditDialog
                dialogTitle={dialogTitle}
                toolTipText={dialogTitle}
                openDialog={editMode}
                setOpenDialog={setEditMode}
                editIcon={editButton}
                triggerCancelEditAction={setPreviousPayStubIdToZeroIfNotSet}
            >
                <Grid2 container direction="column">
                    {getPayStubMiniPreview()}
                    <Box
                        sx={{
                            height: 400,
                            marginLeft: isSmallScreen ? -2.5 : 0,
                            width: isSmallScreen ? '115%' : '100%',
                        }}
                    >
                        <DataGrid
                            rowSelectionModel={previousPayStubs.length ? [previousPayStubs[0].id] : []}
                            columns={columns}
                            rows={previousPayStubs}
                            pageSizeOptions={[5]}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 5,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        payPeriod: !isSmallScreen,
                                    },
                                },
                            }}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            sx={{
                                '& .MuiDataGrid-columnHeaders': {
                                    padding: isSmallScreen ? '0' : '',
                                    margin: isSmallScreen ? '0' : '',
                                    minHeight: isSmallScreen ? '32px !important' : '',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    fontSize: isSmallScreen ? '0.75rem' : '1rem',  // Smaller font size for headers on small screens
                                },
                                '& .MuiDataGrid-cell': {
                                    fontSize: isSmallScreen ? '0.75rem' : '1rem',  // Smaller font size for cells on small screens
                                    paddingRight: isSmallScreen ? '8px !important' : '',
                                    paddingLeft: isSmallScreen ? '8px !important' : '',
                                }
                            }}

                        />
                    </Box>
                    {getPreviousStubFilterMessage(payDate)}
                </Grid2>
            </EditDialog>
        }
        {
            !!selectPayDatePayStubId &&
            <SelectNextPayDateDialog
                payStubId={selectPayDatePayStubId}
                setNextPayDate={nextPayDate => {
                    setPreviousPayStubId(selectPayDatePayStubId, nextPayDate);
                }}
                close={() => setSelectPayDatePayStubId(undefined)}
            />
        }
        {
            promptUserToSelectPrevious &&
            <ErrorDisplay
                stringError={"This employee has previous pay stubs. Select a previous pay stub to ensure correct YTD amounts are included."}
            />
        }
    </Grid2>
}
export default PreviousPayStubDisplayEdit;