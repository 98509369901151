import React, {useContext, useState} from "react";
import {
    GetIncomeTypesQuery,
    IncomeType,
    T4OtherIncomeType,
    useDeleteIncomeTypeMutation,
    useGetIncomeTypesQuery
} from "../graphql/generated/graphql";
import useAnonCookie from "../security/useAnonCookie";
import LoadingErrorDisplay from "../common/LoadingErrorDisplay";
import {DataGrid, GridCellParams, GridColDef, GridRowParams} from "@mui/x-data-grid";
import ResponsiveEditButton from "../components/ResponsiveEditButton";
import OneTwoPayIconWithTooltip from "../common/OneTwoPayIconWithTooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import useViewerStyle from "../components/PayStub/useViewerStyle";
import {Box, Button, Dialog, DialogTitle, Grid2} from "@mui/material";
import {getEmptyIncomeType, SALARY_TAX_TREATMENT} from "../components/PayStub/IncomeTypeSelectEdit";
import IncomeTypeEdit from "../components/PayStub/IncomeTypeEdit";
import CloseDialogButton from "../common/CloseDialogButton";
import {assumedUserContext} from "../components/User/AssumedUserContext";
import useSystemNotices from "../Utils/useSystemNotices";
import GET_INCOME_TYPES from "../graphql/queries/getIncomeTypes";
import {styled} from "@mui/system";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import OneTwoPayTooltip from "../common/OneTwoPayTooltip";

const StyledDataGrid = styled(DataGrid)(() => ({
    "& .MuiDataGrid-row": {
        backgroundColor: "#ffffff", // Default row background color
    },
    "& .MuiDataGrid-row.notEditable": {
        backgroundColor: "#f5f5f5", // Custom background color for specific rows
    },
}));

type IncomeTypeRow = {
    id: number;
    code: string;
    t4OtherIncomeType: T4OtherIncomeType;
}

type MyColDef = GridColDef & {
    field: 'id' | 'label' | 'rateLabel' | 'unitsLabel' | 'displayOrder' | 't4OtherWarning' | 'actions';
}

interface MyGridCellParams extends GridCellParams {
    row: IncomeTypeRow;
}

const warningIcon = React.forwardRef<SVGSVGElement>((props, ref) => (
    <WarningAmberRoundedIcon
        {...props}
        ref={ref}
        fontSize={"large"}
        color={"warning"}
    />
));

const getColumns = (
    editIncomeType: (incomeTypeId: number) => void,
    deleteIncomeType: (incomeTypeId: number) => void,
    isScreenWide: boolean
): MyColDef[] => {
    const idColumn: MyColDef = {
        field: 'id',
        headerName: isScreenWide ? 'Income type ID' : 'ID',

        flex: 1,
        maxWidth: isScreenWide ? 125 : 70,
        cellClassName: 'cell',
    };
    const labelColumn: MyColDef = {
        field: 'label',
        headerName: 'Label',
        flex: 2,
        cellClassName: 'cell',
    };
    const rateLabel: MyColDef = {
        field: 'rateLabel',
        headerName: 'Rate label',
        flex: 1,
        maxWidth: 120,
        cellClassName: 'cell'
    };
    const unitsLabel: MyColDef = {
        field: 'unitsLabel',
        headerName: 'Units label',
        maxWidth: 120,
        minWidth: isScreenWide ? 70 : 40,
        flex: 1.2,
        cellClassName: 'cell',
    };
    const displayOrder: MyColDef = {
        field: 'displayOrder',
        headerName: 'Display order',
        maxWidth: 120,
        minWidth: 70,
        flex: 1,
        cellClassName: 'cell',
    };

    const t4OtherWarning: MyColDef = {
        field: 't4OtherWarning',
        headerName: '',
        maxWidth: 200,
        minWidth: 70,
        flex: 1,
        cellClassName: 'cell',
        renderCell: (params: { id: any, row: any }) => {
            const otherIncomeType = params.row.t4OtherIncomeTypeCode;
            if (!otherIncomeType && !params.row.code) {
                return <OneTwoPayTooltip
                    tipContent={"Requires selection of T4 other income type. Click edit."}
                    TooltipTarget={warningIcon}
                />
            }
        }

    };

    const actionsColumn: MyColDef = {
        field: 'actions',
        headerName: isScreenWide ? 'Actions' : '',
        flex: 2,
        maxWidth: isScreenWide ? 200 : 90,
        minWidth: isScreenWide ? 200 : 90,
        cellClassName: 'cell',
        renderCell: (params: MyGridCellParams) => {
            if (!!params.row.code) {
                return <></>
            }
            return (
                <>
                    <ResponsiveEditButton
                        buttonLabel={"Edit"}
                        editAction={event => {
                            event.stopPropagation();
                            editIncomeType(params.row.id);
                        }}
                        sx={{marginRight: '2rem'}}
                    />
                    <OneTwoPayIconWithTooltip
                        tipContent={'Delete income type'}
                        ariaLabel={'Delete income type'}
                        onClick={() => deleteIncomeType(params.row.id)}
                        tooltipSx={{marginLeft: 0}}
                        icon={<DeleteIcon sx={{fontSize: '2.5rem'}}/>}
                        color={'error'}
                    />
                </>

            )
        }
    };

    const columns = [
        idColumn,
        labelColumn,
        t4OtherWarning,
        actionsColumn,
    ];

    if (isScreenWide) {
        columns.splice(2, 0, rateLabel, unitsLabel, displayOrder);
    }
    return columns;
}

function sortRowsByDisplayOrder(incomeTypesData: GetIncomeTypesQuery) {
    if (!incomeTypesData.incomeTypes) {
        return [];
    }
    const incomeTypes = [...incomeTypesData.incomeTypes];
    return incomeTypes.sort((a, b) => a.displayOrder - b.displayOrder);
}

const IncomeTypes = () => {

    const {getAnonUserId} = useAnonCookie();
    const anonUserId = getAnonUserId();
    const {isScreenWide} = useViewerStyle();
    const {assumedUser} = useContext(assumedUserContext);
    const {sendNotice} = useSystemNotices();

    const [incomeTypeToEdit, setIncomeTypeToEdit] = useState<IncomeType>();
    const [error, setError] = useState<string | undefined>(undefined);

    const {
        data: incomeTypesData,
        loading: incomeTypesLoading,
        error: incomeTypesError
    } = useGetIncomeTypesQuery({
        variables: {
            anonUserId: anonUserId,
            userId: assumedUser.id
        }
    });

    const [
        deleteIncomeType,
        {
            loading: deleteIncomeTypeLoading,
        }] = useDeleteIncomeTypeMutation({
        refetchQueries: [
            {
                query: GET_INCOME_TYPES,
                variables: {
                    userId: assumedUser.id,
                    anonUserId: getAnonUserId(),
                }
            }
        ]
    });


    const addIncomeType = () => {
        setIncomeTypeToEdit(getEmptyIncomeType(SALARY_TAX_TREATMENT))
    };

    const editIncomeType = (incomeTypeId: number) => {
        setIncomeTypeToEdit(incomeTypesData?.incomeTypes.find(incomeType => incomeType.id === incomeTypeId));
    }

    const deleteIncomeTypeNow = (incomeTypeId: number) => {
        deleteIncomeType({
            variables: {
                incomeTypeId: incomeTypeId,
                anonUserId: anonUserId
            }
        })
            .then(result => {
                if (!result.data?.deleteIncomeType.successful) {
                    sendNotice(`Error deleting income type with id: ${incomeTypeId}. Error: ${result.data?.deleteIncomeType.message}.`);
                    setError(`Error deleting income type. Please try again or contact support. Error message: ${result.data?.deleteIncomeType.message}.`);
                }
            })
            .catch(error => {
                setError("Error deleting income type. Please try again or contact support.");
                sendNotice(`Error deleting income type with id: ${incomeTypeId}. Error: ${error.message}`);
            })
    }

    if (incomeTypesLoading || !incomeTypesData) {
        return <LoadingErrorDisplay
            apolloError={incomeTypesError}
            loading={incomeTypesLoading}
        />
    }


    let sortedRows = sortRowsByDisplayOrder(incomeTypesData);
    return <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'flex-start'}
        alignItems={'center'}
        sx={{minHeight: '100vh'}}
    >

        <Grid2
            container
            maxWidth={'1100px'}
            size={12}
        >
            <Grid2 size={12} sx={{my: '2rem'}}>
                <h2>Income types</h2>
            </Grid2>
            <Grid2>
                <LoadingErrorDisplay
                    stringError={error}
                    loading={deleteIncomeTypeLoading}
                />
            </Grid2>
            <Grid2 size={12}>
                <StyledDataGrid
                    columns={getColumns(editIncomeType, deleteIncomeTypeNow, isScreenWide)}
                    rows={sortedRows}
                    getRowClassName={(params: GridRowParams) =>
                        !!params.row.code ? "notEditable" : ""
                    }
                />
            </Grid2>
            <Grid2 container size={12} justifyContent={'flex-end'}>
                <Button
                    variant="contained"
                    sx={{marginTop: '2rem'}}
                    onClick={() => {
                        addIncomeType();
                    }}
                >
                    Add income type
                </Button>
            </Grid2>
            {
                !!incomeTypeToEdit &&
                <Dialog
                    open={!!incomeTypeToEdit}
                >
                    <DialogTitle>
                        Add / edit income type
                        <CloseDialogButton
                            close={() => setIncomeTypeToEdit(undefined)}
                        />
                    </DialogTitle>
                    <IncomeTypeEdit
                        currentPayStubId={undefined}
                        incomeType={incomeTypeToEdit}
                        setIncomeType={() => {
                        }}
                        close={() => setIncomeTypeToEdit(undefined)}
                    />
                </Dialog>
            }
        </Grid2>
    </Box>

}
export default IncomeTypes;